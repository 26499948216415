import React from 'react';
import { Card, CardGroup } from 'react-bootstrap';
import Icon1 from './icon-1.png';
import Icon2 from './icon-2.png';
import Icon3 from './icon-3.png';

function Cards() {
  return (
    <CardGroup>
      <Card className="d-flex flex-column justify-content-center align-items-center border-0">
        <Card.Img variant="top" src={Icon1} style={{ width: '100px' }} />
        <Card.Body>
          <Card.Title className="text-center">DOŚWIADCZENIE</Card.Title>
          <Card.Text className="text-center">
            Nasze biuro rachunkowe funkcjonuje od 2009 roku.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card className="d-flex flex-column justify-content-center align-items-center border-0">
        <Card.Img variant="top" src={Icon2} style={{ width: '100px' }} />
        <Card.Body>
          <Card.Title className="text-center">JAKOŚĆ</Card.Title>
          <Card.Text className="text-center">
            Posiadamy licencję na prowadzenie księg rachunkowych.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card className="d-flex flex-column justify-content-center align-items-center border-0">
        <Card.Img variant="top" src={Icon3} style={{ width: '100px' }} />
        <Card.Body>
          <Card.Title className="text-center">PROFESJONALIZM</Card.Title>
          <Card.Text className="text-center">
            Gwarantujemy wysoką jakość wykonywanych usług.
          </Card.Text>
        </Card.Body>
      </Card>
    </CardGroup>
  );
}

export default Cards;
