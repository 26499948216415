import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Info from './Info.js'
import Offer from './Offer.js'
import Links from './Links.js'
import Contact from './Contact.js'
import Footer from './Footer.js'
import Cards from './Cards.js'
import AboutCompany from './AboutCompany.js';
import logo from './logo.png'; // Import your logo file

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    handleToggle = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        this.setState({ expanded: false });
    };

    render() {
        return (
            <div>
                <Navbar bg="dark" variant="dark" expand="lg" className="sticky-top" expanded={this.state.expanded}>
                    <Navbar.Brand onClick={() => { this.scrollToSection('section0'); this.setState({ expanded: false }); }}>
                        <img src={logo} alt="Logo" className="logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={this.handleToggle} aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto justify-content-center">
                            <Nav.Link onClick={() => { this.scrollToSection('section1'); this.setState({ expanded: false }); }}>O FIRMIE</Nav.Link>
                            <Nav.Link onClick={() => { this.scrollToSection('section2'); this.setState({ expanded: false }); }}>OFERTA</Nav.Link>
                            <Nav.Link onClick={() => { this.scrollToSection('section3'); this.setState({ expanded: false }); }}>AKTUALNOŚCI</Nav.Link>
                            <Nav.Link onClick={() => { this.scrollToSection('section4'); this.setState({ expanded: false }); }}>KONTAKT</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="content">
                    <Info />
                    <Cards />
                    <AboutCompany/>
                    <Offer />
                    <Links />
                    <Contact />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default App;
