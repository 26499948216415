import React from 'react';
import { Card, CardGroup } from 'react-bootstrap';
import Icon1 from './of-icon1.png';
import Icon2 from './of-icon2.png';
import Icon3 from './of-icon3.png';
import Icon4 from './of-icon4.png';
import Icon5 from './of-icon5.png';
import Icon6 from './of-icon6.png';

function Offer() {
  return (
    <div id="section2" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
      <h2 className="display-4 text-center mb-4 border-bottom pb-2">Oferta</h2>
      <div className="d-flex flex-wrap justify-content-center">
        <CardGroup>
          <Card className="d-flex flex-column justify-content-center align-items-center border-0">
            <Card.Img variant="top" src={Icon1} style={{ width: '100px' }} />
            <Card.Body>
              <Card.Title className="text-center">KSIĘGA PRZYCHODÓW I ROZCHODÓW</Card.Title>
            </Card.Body>
          </Card>
          <Card className="d-flex flex-column justify-content-center align-items-center border-0">
            <Card.Img variant="top" src={Icon2} style={{ width: '100px' }} />
            <Card.Body>
              <Card.Title className="text-center">KSIĘGI RACHUNKOWE</Card.Title>
            </Card.Body>
          </Card>
          <Card className="d-flex flex-column justify-content-center align-items-center border-0">
            <Card.Img variant="top" src={Icon3} style={{ width: '100px' }} />
            <Card.Body>
              <Card.Title className="text-center">EWIDENCJA PRZYCHODÓW (RYCZAŁT) I KARTA PODATKOWA</Card.Title>
            </Card.Body>
          </Card>
        </CardGroup>
        <CardGroup>
          <Card className="d-flex flex-column justify-content-center align-items-center border-0">
            <Card.Img variant="top" src={Icon4} style={{ width: '100px' }} />
            <Card.Body>
              <Card.Title className="text-center">PROWADZENIE DOKUMENTACJI KADROWO-PŁACOWEJ</Card.Title>
            </Card.Body>
          </Card>
          <Card className="d-flex flex-column justify-content-center align-items-center border-0">
            <Card.Img variant="top" src={Icon5} style={{ width: '100px' }} />
            <Card.Body>
              <Card.Title className="text-center">REJESTRACJA DZIAŁALNOŚCI GOSPODARCZEJ</Card.Title>
            </Card.Body>
          </Card>
          <Card className="d-flex flex-column justify-content-center align-items-center border-0">
            <Card.Img variant="top" src={Icon6} style={{ width: '100px' }} />
            <Card.Body>
              <Card.Title className="text-center">DORADZTWO KSIĘGOWE I PODATKOWE</Card.Title>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
    </div>
  );
}

export default Offer;
