import React from 'react';
import { Carousel } from 'react-bootstrap';
import Logo1 from './logo_big.png';
import Logo2 from './logo_big2.png';
import Logo3 from './logo_big3.png';


function Info() {
  return (
    <Carousel id='section0' interval={4000} style={{ height: '450px' }}>
      <Carousel.Item>
        <img src={Logo1} className="d-block w-100" alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={Logo2} className="d-block w-100" alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={Logo3} className="d-block w-100" alt="Third slide" />
      </Carousel.Item>
    </Carousel>
  );
}

export default Info;
