import React from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaFacebookSquare } from "react-icons/fa";
import { SlLocationPin } from "react-icons/sl";



class Offer extends React.Component {
    render() {
        return (
            <Container id='section4' style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                <h2 className="display-4 text-center mb-4 border-bottom pb-2">Kontakt</h2>
                <p className="lead text-center">🤝Z przyjemnością odpowiem na wszystkie pytania. Zapraszam do kontaktu🤝</p>
                <p className="display-6 text-center mb-4">Biuro rachunkowe EKSPERT mgr EWA ZABŁOCKA</p>
                <p className="lead text-center"><SlLocationPin />ul. Browarna 25-27, lok. 19, 21-400 Łuków</p>
                <p className="lead text-center">NIP: 825-177-53-52</p>
                <p className="display-6 text-center mb-4">Kontakt telefoniczny:</p>
                <p className="lead text-center">☎501 497 013 - Ewa Zabłocka</p>
                <p className="lead text-center">📞573 263 208 - Biuro</p>
                <p className="display-6 text-center mb-4">Godziny pracy:</p>
                <p className="lead text-center">🕒pon.-pt. 8:00-16:00, sobota 9:00-13:00</p>
                <p className="lead text-center">📧Email: <a href="mailto:ekspertksiegowosc2@gmail.com">ekspertksiegowosc2@gmail.com</a></p>
                <p className="lead text-center"><FaFacebookSquare /><a href="https://fb.com/biuroekspert.com/">fb.com/biuroekspert.com</a></p>
            </Container>
        );
    }
}

export default Offer;
