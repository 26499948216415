import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'; // Assuming you're using react-bootstrap for grid layout
import Mflogo from './mflogo.png';
import Kaslogo from './kaslogo.png';

function Links() {
  return (
    <section id="section3" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
      <Container>
        <Row>
          <Col>
              <h3 className="display-8 text-center mb-4">Aktualności Podatkowe</h3>
              <div className="lead text-center">
              <ul>
<li><a href="https://poradnikprzedsiebiorcy.pl/-specjalny-rachunek-vat-a-split-payment-warto-wiedziec">Specjalny rachunek VAT a split payment - warto wiedzieć!</a></li>
<li><a href="https://poradnikprzedsiebiorcy.pl/-limit-do-10-000-zl-na-jednorazowe-rozliczenie-kosztow-firmowych">Limit do 10 000 zł na jednorazowe rozliczenie kosztów firmowych!</a></li>
<li><a href="https://poradnikprzedsiebiorcy.pl/-split-payment-czyli-podzielona-platnosc-na-czym-polega">Split payment, czyli podzielona płatność - na czym polega?</a></li>
<li><a href="https://poradnikprzedsiebiorcy.pl/-limit-transakcji-gotowkowej-a-obowiazek-platnosci-przelewem">Limit transakcji gotówkowej a obowiązek płatności przelewem</a></li>
<li><a href="http://www.zus.pl/baza-wiedzy/biezace-wyjasnienia-komorek-merytorycznych/firmy/-/publisher/details/1/zmiany-w-ubezpieczeniach-wprowadzone-przez-konstytucje-biznesu-/1874820">ZUS&nbsp; "Ulga na start"</a></li>
<li><a href="https://poradnikprzedsiebiorcy.pl/-jednorazowa-amortyzacja-srodkow-trwalych">Jednorazowa amortyzacja środków trwałych - nowa ulga!</a></li>
</ul>
              </div>
          </Col>
          <Col>
              <h3 className="display-8 text-center mb-4">Przydatne Linki</h3>
              <div className="lead text-center">
                    <a href="http://www.lubelskie.kas.gov.pl/urzad-skarbowy-w-lukowie">
                        <img src={Kaslogo} alt="logo"></img>
                    </a> <br></br>
                    <a href="http://www.mf.gov.pl/ministerstwo-finansow">
                        <img src={Mflogo} alt="logo"></img>
                        </a>                 
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Links;
