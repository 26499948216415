import React from 'react';

function Footer() {
    return (
        <footer className="footer bg-dark text-white text-center py-1">
            <div className="container">
                <p className="mb-0">
                    Copyright © 2017 BiuroEkspert.com -{' '}
                    <a href="https://www.biuroekspert.com/" className="text-white" title="Biuro rachunkowe Łuków">
                        Biuro rachunkowe Łuków
                    </a>
                    , księgowa, usługi księgowe, księgi rachunkowe.
                </p>
            </div>
        </footer>
    );
}

export default Footer;
